@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Raleway Black";
  src: url("./themes/fonts/Raleway/Raleway-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway ExtraBold";
  src: url("./themes/fonts/Raleway/Raleway-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Bold";
  src: url("./themes/fonts/Raleway/Raleway-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway SemiBold";
  src: url("./themes/fonts/Raleway/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Medium";
  src: url("./themes/fonts/Raleway/Raleway-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Regular";
  src: url("./themes/fonts/Raleway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Thin";
  src: url("./themes/fonts/Raleway/Raleway-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway Light";
  src: url("./themes/fonts/Raleway/Raleway-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway ExtraLight";
  src: url("./themes/fonts/Raleway/Raleway-ExtraLight.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 768px) {
  .container {
    min-height: 400px;
    height: calc(100vh - 300px);
  }
}

@media screen and (orientation: landscape) {
  .container {
    min-height: 400px;
    height: initial !important;
    margin-bottom: 20px;
  }
}

@media (max-height: 600px) {
  .container {
    min-height: 400px;
    height: calc(100vh - 300px);
  }
}
